// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poetry-index-js": () => import("./../src/pages/poetry/index.js" /* webpackChunkName: "component---src-pages-poetry-index-js" */),
  "component---src-pages-poetry-recordings-js": () => import("./../src/pages/poetry/recordings.js" /* webpackChunkName: "component---src-pages-poetry-recordings-js" */),
  "component---src-pages-the-original-theatre-of-the-city-of-new-york-index-js": () => import("./../src/pages/the-original-theatre-of-the-city-of-new-york/index.js" /* webpackChunkName: "component---src-pages-the-original-theatre-of-the-city-of-new-york-index-js" */),
  "component---src-templates-content-page-js": () => import("./../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */)
}

